
import { formatDateSimple } from '@/filters/formatDate';
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import { GetHourPlaceId, GetHourPlaceVehicle } from '@/types/response/locations';
import { Options, Vue } from 'vue-class-component';
import imageCar from '@/assets/car.png'
import { formatCurrency } from '@/utils';

@Options({
    name: 'DetailSectorPayment',
    components: {}
})
export default class DetailSectorPayment extends Vue {
    public imageCar = imageCar;
    public airportStore = useAirportBookingStore();
    public formatDateSimple = formatDateSimple;
    public formatCurrency = formatCurrency;
    public hours: string[] = []

    public vehiclesAvailable: GetHourPlaceVehicle[] = [];

    get step() {
        return this.airportStore.step
    }

    get place() {
        return this.airportStore.placeInfo
    }

    get dataForm() {
        return this.airportStore.form
    }

    get emptyResult() {
        return this.airportStore.emptyResult
    }

    get vehicleAiportSelected() {
        return this.airportStore.vehicleAiportSelected
    }

    get hourSelected() {
        return this.airportStore.hourSelected
    }

    timeToMinutes(time: string) {
        const [hours, minutes] = time.split(":").map(Number);
        return hours * 60 + minutes;
    }

    minutesToTime(minutes: number) {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
    }

    generateTimeIntervals(hourMin: string, hourFinish: string, currentTime?: string) {
        const intervals: string[] = [];
        let startMinutes = this.timeToMinutes(hourMin);
        const endMinutes = this.timeToMinutes(hourFinish);

        if (currentTime) {
            const currentMinutes = this.timeToMinutes(currentTime);
            if (startMinutes < currentMinutes + 15) {
                startMinutes = Math.ceil((currentMinutes + 15) / 15) * 15;
            }
        }

        for (let minutes = startMinutes; minutes < endMinutes; minutes += 15) {
            intervals.push(this.minutesToTime(minutes));
        }

        return intervals;
    }

    getAllowedTimeSlots(data: GetHourPlaceId[], currentTime?: string) {
        return data
            .filter((slot) => !slot.isBlocked)
            .flatMap((slot) => this.generateTimeIntervals(slot.hourMin, slot.hourFinish, currentTime));
    }

    findTimeSlot(selectedTime: string) {
        if (!this.place) {
            return
        }
        const selectedMinutes = this.timeToMinutes(selectedTime);

        for (const slot of this.place.hours) {
            if (!slot.isBlocked) {
                const startMinutes = this.timeToMinutes(slot.hourMin);
                const endMinutes = this.timeToMinutes(slot.hourFinish);
                if (selectedMinutes >= startMinutes && selectedMinutes < endMinutes) {
                    return slot
                }
            }
        }
        return null;
    }

}
