
import { SectorApi } from '@/api/sector';
import { GetSectors } from '@/types/response/locations';
import { getErrorCatch } from '@/utils';
import { Options, Vue } from 'vue-class-component';
import { Header } from 'vue3-easy-data-table';


@Options({
    name: 'UsuariosView'
})
export default class SectorsView extends Vue {
    public visibleCreateSector = false;
    public apiSector = new SectorApi()
    public loadingTable= false
    public items :GetSectors[] = [];
    public headers: Header[] = [
        { text: "Nombre", value: "name" },
        { text: "Horas", value: "hours" },
        { text: "", value: "actions" },
    ];

    mounted(): void {
        this.getSectors()
    }

    async getSectors(){
        this.loadingTable = true;
        try {
            this.items = await this.apiSector.get()
        } catch (error) {
            getErrorCatch(error)
        }
        this.loadingTable = false;
    }
}
