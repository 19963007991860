import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import { LOCATIONS_ENDPOINTS } from "./endpoint";
import { GetPlaceIdRes, GetSearchAddress } from "@/types/response/locations";

export class LocationsApi {
  private request = new API(ROUTES_GLOBAL.LOCATIONS);
  private api: AxiosInstance = this.request.getInstance();

  async searchAddress(address : string){
    // para cancelamientos
    this.request.cancelRequest();
    const {data} = await this.request.get<GetSearchAddress[]>(LOCATIONS_ENDPOINTS.SEARCH+ "?address=" + encodeURI(address));
    return data
  }

  async getZone(id: string){
    console.log("va por aqui?")
    const {data} = await this.api.get<GetPlaceIdRes>(LOCATIONS_ENDPOINTS.GET_PLACE + id)
    return data;
  }
}
